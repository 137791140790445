'use client'

import * as Sentry from '@sentry/nextjs'
import { ERROR } from '@/components/Error/consts'

export default function GlobalError({ error }: { error: Error }) {
  Sentry.captureException(error)

  const handleBack = () => {
    if (typeof window !== 'undefined') {
      if (window.history.length > 1) {
        window.history.back()
      } else {
        window.location.href = '/'
      }
    }
  }

  return (
    <html>
      <body>
        <div className="h-screen">
          <div className="w-full h-full flex items-center justify-center text-center">
            <div className="px-md">
              <div className="mb-2.5">
                <strong className="whitespace-pre-wrap text-title">
                  {ERROR['error'].title}
                </strong>
              </div>
              <div className="mb-5">
                <div className="whitespace-pre-wrap px-4 text-center">
                  {ERROR['error'].content}
                </div>
              </div>
              <button
                type="button"
                className="w-full p-2.5 rounded-sm bg-gray-20"
                onClick={handleBack}
              >
                <span className="text-blue-100">이전 페이지로 돌아가기</span>
              </button>
            </div>
          </div>
        </div>
      </body>
    </html>
  )
}
